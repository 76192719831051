import { Product, Stock } from "@/graphql/types";
import { activeActivity } from "@/plugins/i18n";
import { getPrices, numberSeparators, totalStock } from "@/graphql/utils/utils";
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
import { TicketRow } from "@/graphql/ticket/create.ticket";
import { useToast } from "primevue/usetoast";
import { getActiveTerminal } from "@/graphql/sequence/active.sequence";

export const usePosProduct = (emit: any) => {
  const { t, n } = useI18n();
  const saleRow = reactive<TicketRow>({
    name: "",
    productId: 0,
    stockId: "",
    reference: "",
    quantity: 1,
    changeable: null,
    price: 0,
    vat: 0,
  });
  const { printStockPos, allowDiscountLines } = getActiveTerminal();
  const stocks = ref<Stock[]>([]);
  const stockOverlay = ref(null);
  const toast = useToast();

  function stockStatus(product: Product) {
    const symbol = activeActivity.value.currencySymbol;
    const local = numberSeparators[activeActivity.value.separator];
    if (product.notStorable) return getPrices(product);
    switch (product.stocks.length) {
      case 0:
        return t("stock.no");
      case 1:
        return n(product.stocks[0].inclTax, "decimal", local) + " " + symbol;
      default:
        return t("stock.stockNumber", { n: product.stocks.length });
    }
  }

  function totalStatus(product: Product) {
    if (product.type > 0 || printStockPos == 0) return "";
    //this.$tm("product.types")[type]
    else if (!product.stocks.length) return "";

    const total = totalStock(product.stocks);

    const formatted = n(
      total,
      "decimal",
      numberSeparators[activeActivity.value.separator]
    );

    if (total < 0)
      return `<span style="padding: 0 5px; background: #D32F2F;">${formatted}</span>`;
    if (product.threshold && total < product.threshold)
      return `<span style="background: #F57C00;padding: 0 5px">${formatted}</span>`;

    return formatted;
  }
  function setupPriceEditor() {
    const openEdit = saleRow.price == 0;
    if (!saleRow.changeable) saleRow.changeable = openEdit;
    // open input place by default if quantity is zero, nul if not still changeable
    saleRow.changeable = !saleRow.changeable ? null : openEdit;
  }
  function onAddShop(event: any, product: Product) {
    saleRow.name = product.name;
    saleRow.reference = product.reference;
    saleRow.stockId = "";
    saleRow.productId = 0;
    //Vérifier si c'est un produit de remise

    saleRow.quantity = 1;
    saleRow.changeable = product.changeable == 1;
    if (product.type > 0) {
      saleRow.productId = product.id;
      saleRow.price = product.notStorable.inclTax;
      setupPriceEditor();
      emit("addToTicketRow", saleRow);
    } else if (product.stocks.length) {
      saleRow.stockId = product.stocks[0].id;
      saleRow.price = product.stocks[0].inclTax;
      saleRow.vat = product.stocks[0].vat;
      switch (product.stocks.length) {
        case 1:
          setupPriceEditor();
          emit("addToTicketRow", saleRow);
          stocks.value.length = 0;
          break;
        default:
          stocks.value = [...product.stocks];
          stockOverlay.value.toggle(event);
          break;
      }
    } else {
      toast.add({
        severity: "warn",
        summary: t("stock.stock"),
        detail: t("stock.haveNoStock"),
        life: Number(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  }

  function onShop(event: any, product: Product, hasSequence: boolean) {
    if (hasSequence) onAddShop(event, product);
    else {
      toast.add({
        severity: "warn",
        summary: t("pos.openCasing"),
      });
    }
  }

  function onQuantityValidate() {
    const { inclTax, vat } = stocks.value.find((s) => s.id === saleRow.stockId);
    saleRow.price = inclTax;
    saleRow.vat = vat;
    setupPriceEditor();
    emit("addToTicketRow", saleRow);
    stockOverlay.value.hide();
  }

  function onCodeBarSearch(data: any[], products: Product[]) {
    const product = products.find(
      (p) => p.reference.toLowerCase() == data[1].toLowerCase()
    );
    if (!product) {
      toast.add({
        severity: "info",
        summary: t("product.codeBarSummary"),
        detail: t("product.codeBarDetail", { code: data[1] }),
        life: 1500,
      });
    } else onAddShop(data[0], product);
  }

  return {
    stockOverlay,
    stockStatus,
    totalStatus,
    stocks,
    saleRow,
    onShop,
    onQuantityValidate,
    onCodeBarSearch,
    allowDiscountLines: Boolean(allowDiscountLines),
  };
};
